export const removeNullish = (obj) => {
  const newObject = Object.entries(obj).reduce((accumulator, [key, value]) => {
    if ([null, undefined, ''].includes(value)) return accumulator;

    accumulator[key] = value;
    return accumulator;
  }, {});

  return newObject;
};

export const removeFalsy = (obj) => {
  const truthyObject = Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (!value) return accumulator;

    accumulator[key] = value;
    return accumulator;
  }, {});

  return truthyObject;
};
