export const RESOLVED_STATUS_OPTIONS = [
  {
    value: null,
    text: '-- Lọc theo trạng thái --',
  },
  {
    value: 1,
    text: 'Đã đồng bộ',
  },
  {
    value: 2,
    text: 'Thất bại',
  },
];

export const EXCEPTION_FROM_OPTIONS = [
  {
    value: null,
    text: '-- Lọc theo nguồn lỗi --',
  },
  {
    value: 1,
    text: 'Core',
  },
  {
    value: 2,
    text: 'Fast',
  },
];

export const MODULE_OPTIONS = [
  { value: null, text: '-- Lọc theo module --' },
  { value: 'productNew', text: 'Tạo mới sản phẩm' },
  { value: 'productUpdate', text: 'Cập nhật sản phẩm' },
  { value: 'categoryNew', text: 'Tạo mới danh mục sản phẩm' },
  { value: 'categoryUpdate', text: 'Cập nhật danh mục sản phẩm' },
  { value: 'providerNew', text: 'Tạo mới nhà cung cấp' },
  { value: 'providerUpdate', text: 'Cập nhật nhà cung cấp' },
  { value: 'businessNew', text: 'Tạo mới khách hàng doanh nghiệp' },
  { value: 'businessUpdate', text: 'Cập nhật khách hàng doanh nghiệp' },
  { value: 'storeNew', text: 'Tạo mới cửa hàng' },
  { value: 'storeUpdate', text: 'Cập nhật cửa hàng' },
];

export const TABLE_FIELDS = [
  {
    key: 'referenceId',
    label: 'Id tham chiếu',
    thStyle: { textAlign: 'center', width: '3%' },
    tdClass: 'text-center align-middle',
  },
  {
    key: 'moduleName',
    label: 'Tên module',
    thStyle: { textAlign: 'center', width: '4%' },
    tdClass: 'text-left align-middle',
  },
  {
    key: 'id',
    label: 'Chi tiết',
    thStyle: { textAlign: 'center', width: '2%' },
    tdClass: 'text-center align-middle',
  },
  {
    key: 'isResolved',
    label: 'Trạng thái',
    thStyle: { textAlign: 'center', width: '2%' },
    tdClass: 'text-center align-middle',
  },
  {
    key: 'action',
    label: 'Hành động',
    thStyle: { width: '1%', textAlign: 'center' },
    tdClass: 'text-center align-middle',
  },
];
